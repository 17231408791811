.modal-mask {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 5;
}

.modal-container {
    width: 90%; 
    max-width: 500px;
    min-width: 350px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 7px 7px 3px rgba(0, 0, 0, 0.5);
    z-index: 5;
}